import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { Form, Input, Button, Upload, Row } from "antd";

import { InlineSpinner } from "common/components";

const AppreciateSomeone = ({ isCreating = false, handleCreatePost }) => {
  const [imgFileList, setImgFileList] = useState([]);

  const onFinish = (values) => {
    const formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }
    formData.append("image", imgFileList[0]);
    handleCreatePost({ formData });
  };

  return (
    <div>
      <Form onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
          required={false}
        >
          <Input placeholder="Ex: Jon Doe" />
        </Form.Item>

        <Form.Item
          label="User Info"
          name="user_info"
          rules={[
            {
              required: true,
              message: "Please input user info",
            },
          ]}
          required={false}
        >
          <Input placeholder="Ex: CEO Sonic Solns" />
        </Form.Item>

        <Form.Item
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: "Please input message",
            },
          ]}
          required={false}
        >
          <Input placeholder="Ex: Congratulte John and Mary for winning the Generative AI Hackathon" />
        </Form.Item>

        <Form.Item
          label="Image"
          name="image"
          rules={[
            {
              required: true,
              message: "Please select an image",
            },
          ]}
          required={false}
        >
          <Upload
            accept={"image/png, image/gif, image/jpeg"}
            fileList={imgFileList}
            beforeUpload={(file, fileList) => {
              setImgFileList(fileList);
            }}
            onRemove={() => {
              setImgFileList([]);
            }}
          >
            <Button>Upload</Button>
          </Upload>
        </Form.Item>

        <Row justify={"center"}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isCreating && (
                <InlineSpinner className="mr-8" fontSize={24} color="white" />
              )}
              Generate Post
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

AppreciateSomeone.propTypes = {};

export default AppreciateSomeone;
