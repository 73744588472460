import axios from "axios";

export const createPostApi = async ({
  brandId,
  ideaCategoryId,
  formData,
  values,
}) => {
  formData.append("idea_category_id", ideaCategoryId);
  const { data } = await axios.post(`/brands/${brandId}/posts`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

export const extractInfoApi = async ({ blogUrl }) => {
  const { data } = await axios.get(`/utils/blog?blog_url=${blogUrl}`);

  return data;
};
