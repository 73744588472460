import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "antd";

import ImageChangeImageDialog from "./ImageChangeImageDialog";

const ImageChangeImageBtn = observer(
  ({ store, isAiImages, brandId, postId, photoOptions, element, elements }) => {
    const [isDialgoOpen, setIsDialogOpen] = useState(false);

    const handleIsDialogOpen = () => {
      setIsDialogOpen(!isDialgoOpen);
    };

    const handleClick = (url) => {
      element.set({
        src: url,
      });
      setIsDialogOpen(false);
    };

    return (
      <div>
        {isDialgoOpen && (
          <div>
            <ImageChangeImageDialog
              isAiImages={isAiImages}
              isOpen={isDialgoOpen}
              brandId={brandId}
              postId={postId}
              photoOptions={photoOptions}
              handleClick={handleClick}
              onClose={handleIsDialogOpen}
            />
          </div>
        )}
        <Button onClick={handleIsDialogOpen}>ChangeImage</Button>
      </div>
    );
  }
);
ImageChangeImageBtn.propTypes = {};

export default ImageChangeImageBtn;
