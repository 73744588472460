import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { notification } from "antd";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { useSelectedBrand } from "store";
import Header from "./components/Header";
import Event from "./options/Event";
import BlogPost from "./options/BlogPost";
import AppreciateSomeone from "./options/AppreciateSomeone";
import BehindTheScenes from "./options/BehindTheScenes";
import Testimonial from "./options/Testimonial";
import Announcment from "./options/Announcment";
import CreateAssetFromIdea from "posts/components/assets/CreateAssetFromIdea";

import { createPostApi } from "./api";

const getTitle = (category = "") => {
  console.log({ category });
  if (category === "Promote Event") {
    return "Promote Event";
  }

  if (category === "Promote an article") {
    return "Promote an article";
  }

  if (category === "Appreciate Someone") {
    return "Appreciate Someone";
  }

  if (category === "Behind the Scenes") {
    return "Behind The Scense";
  }

  if (category === "Customer Testimonials") {
    return "Customer Testimonials";
  }

  if (category === "Company News and Updates") {
    return "Company News and Updates";
  }
};

const SelectedComponent = ({
  isCreating = false,
  option = "",
  handleCreatePost = () => {},
}) => {
  console.log({ option });

  if (option === "Promote Event") {
    // Promote Event
    return (
      <Event isCreating={isCreating} handleCreatePost={handleCreatePost} />
    );
  }

  if (option === "Promote an article") {
    //Promote-an-article
    return (
      <BlogPost isCreating={isCreating} handleCreatePost={handleCreatePost} />
    );
  }

  if (option === "Appreciate Someone") {
    // Appreciate Someone
    return (
      <AppreciateSomeone
        isCreating={isCreating}
        handleCreatePost={handleCreatePost}
      />
    );
  }

  if (option === "Behind the Scenes") {
    // Behind The Scenes
    return (
      <BehindTheScenes
        isCreating={isCreating}
        handleCreatePost={handleCreatePost}
      />
    );
  }

  if (option === "Customer Testimonials") {
    // Customer Testimonial
    return (
      <Testimonial
        isCreating={isCreating}
        handleCreatePost={handleCreatePost}
      />
    );
  }

  if (option === "Company News and Updates") {
    //Company News & Updates
    return (
      <Announcment
        isCreating={isCreating}
        handleCreatePost={handleCreatePost}
      />
    );
  }

  // TODO: Highlight a product or service 2
  // TODO: Brand Promotions 9
  // TODO: Interactive Content (Quizes, Polls, Contests etc) 8
  // TODO: Industry News / Trends 7
};

const PromoteYourWork = (props) => {
  const navigate = useNavigate();
  const [selectedBrand] = useSelectedBrand();

  const { brandId, option, category } = useParams();

  const [isEditingPost, setIsEditingPost] = useState({
    show: false,
    postId: null,
  });

  const { mutate: createPost, isLoading: isCreating } = useMutation(
    createPostApi,
    {
      retry: false,
      onSuccess: (data) => {
        setIsEditingPost({
          show: true,
          postId: data?.id,
        });
      },
      onError: () => {
        notification.error({
          message:
            "Something unexpected happened, please try again later if the posts was not created.",
          duration: 0,
        });
      },
    }
  );

  const handleCreatePost = ({ formData, values = {} }) => {
    createPost({
      brandId,
      ideaCategoryId: option,
      values,
      formData,
    });
  };

  const handleClosePost = () => {
    setIsEditingPost({
      show: false,
      postId: null,
    });
  };

  const handleSavePost = () => {
    navigate(`/brand/${selectedBrand?.id}`);
  };

  return (
    <div>
      <Header
        backUrl={`/create-new-post/${brandId}`}
        title={getTitle(category)}
      />
      {isEditingPost && (
        <CreateAssetFromIdea
          open={isEditingPost?.show}
          brandId={brandId}
          postId={isEditingPost?.postId}
          onClose={handleClosePost}
          onSave={handleSavePost}
        />
      )}
      <div
        style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}
      >
        <SelectedComponent
          option={category}
          isCreating={isCreating}
          handleCreatePost={handleCreatePost}
        />
      </div>
    </div>
  );
};

PromoteYourWork.propTypes = {};

export default PromoteYourWork;
