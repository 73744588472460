import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";
import Brands from "brands/Brands";
import PostsList from "posts/components/PostsList";
import EditAssetFromURL from "posts/components/assets/EditAssetFromURL";
import Editor from "common/components/editor/Editor";
import PromoteYourWork from "promote-your-work/PromoteYourWork";
import RedirectToPost from "posts/components/RedirectToPost";
import NewPostCategoryList from "posts/components/NewPostsCategoryList";
import CreateAssetFromIdea from "posts/components/assets/CreateAssetFromIdea";
import LoadFromSaved from "load-from-saved/LoadFromSaved";
import StudioEditor from "studio-editor/StudioEditor";

import reportWebVitals from "./reportWebVitals";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        exact: true,
        path: "/brands",
        element: <Brands />,
      },
      {
        path: "/brand/:brandId",
        element: <PostsList />,
      },
      {
        path: "/create-new-post/:brandId/idea/:ideaId",
        element: <RedirectToPost />,
      },
      {
        path: "/create-new-post/:brandId",
        element: <NewPostCategoryList />,
      },
      {
        path: "editor",
        element: <Editor />,
      },
      { path: "load-from-saved-state", element: <LoadFromSaved /> },
    ],
  },
  {
    path: "studio-editor",
    element: <StudioEditor />,
  },
  {
    path: "/brand/:brandId",
    element: <PostsList />,
  },
  {
    path: "/brand/:brandId/post/:postId",
    element: <EditAssetFromURL />,
  },
  {
    exact: true,
    path: "/create-new-post/:brandId/idea/:ideaId/post/:postId",
    element: <CreateAssetFromIdea />,
  },
  {
    path: "/create-new-post/:brandId/promote-your-work/:category/option/:option/",
    element: <PromoteYourWork />,
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
