import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { Row, Collapse, notification, Typography } from "antd";

import { useParams, useNavigate } from "react-router";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { BoxLoader, InlineSpinner } from "common/components";
import { useHeader } from "store";
import PendingTasks from "./PendingTasks";
import { generateIdeaMutationApi } from "posts/apis/postsApi";
import { useSelectedBrand } from "store";
import { getIdeaCategoryListApi } from "posts/apis/postsApi";

const { Text } = Typography;

const NewPostCategoryList = (props) => {
  const queryClient = useQueryClient();

  const { brandId } = useParams();

  const [selectedBrand] = useSelectedBrand();

  const navigate = useNavigate();

  const [categoryNameMap, setCategoryNameMap] = useState({});
  const [, setHeader] = useHeader();

  useEffect(() => {
    setHeader(`Create New Post for ${selectedBrand?.name}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading, data } = useQuery(
    ["getIdeaCategoryList", brandId],
    getIdeaCategoryListApi,
    {
      enabled: Boolean(brandId),
      onSuccess: (data = []) => {
        const catMap = {};
        data.forEach((category) => {
          category.idea_categories.forEach((category) => {
            catMap[category.id] = category.name;
          });
        });

        setCategoryNameMap(catMap);
      },
    }
  );

  const { isLoading: isGeneratingIdea, mutate: generateIdea } = useMutation(
    generateIdeaMutationApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["getPendingTasks"],
        });
      },
      onError: () => {
        notification.error({
          message:
            "Something unexpected happened, please try again later if the posts was not created.",
          duration: 0,
        });
      },
    }
  );

  const handleClickOption = (optionId, category, option) => {
    if (category === "Talk about your Work") {
      navigate(
        `/create-new-post/${brandId}/promote-your-work/${option}/option/${optionId}`
      );
    } else {
      generateIdea({ brandId, ideaId: optionId });
    }
  };

  return (
    <div>
      <Row style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          {isLoading ? (
            <BoxLoader />
          ) : (
            <div>
              {data?.map((category) => (
                <div key={category?.name}>
                  <Collapse
                    items={[
                      {
                        key: "1",
                        label: category?.name,
                        children: (
                          <div>
                            {category?.idea_categories?.map((option) => (
                              <div
                                key={option?.id}
                                style={{
                                  margin: "8px",
                                  padding: "4px",
                                  paddingLeft: "8px",
                                  border: "1px solid #e3e3e3",
                                  borderRadius: "5px",
                                }}
                                onClick={() => {
                                  handleClickOption(
                                    option?.id,
                                    category?.name,
                                    option?.name
                                  );
                                }}
                              >
                                {option?.name}
                              </div>
                            ))}
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {isGeneratingIdea && (
          <Row
            justify={"center"}
            align={"middle"}
            style={{ width: "100%", margin: "16px" }}
          >
            <Text style={{ marginRight: "8px" }}>Generating Post</Text>
            <InlineSpinner fontSize={20} />
          </Row>
        )}

        <div>
          <PendingTasks brandId={brandId} categoryNameMap={categoryNameMap} />
        </div>
      </Row>
    </div>
  );
};

NewPostCategoryList.propTypes = {};

export default NewPostCategoryList;
