import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Typography, Row, Button, Dropdown, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { BoxLoader } from "common/components";
import EditAsset from "posts/components/assets/EditAsset";
import { useSelectedBrand, useHeader } from "store";
import { ReactComponent as ChatbotIcon } from "assets/ChatbotIcon.svg";
import { getBrandPostsApi, deletePostApi } from "posts/apis/postsApi";

const { Text } = Typography;

const dropDownItems = [
  {
    label: "Delete Post",
    key: "delete-post",
    icon: <DeleteOutlined />,
  },
];

const PostsList = (props) => {
  const queryClient = useQueryClient();

  const [isEditingPost, setIsEditingPost] = useState({
    showEditor: false,
    postId: null,
  });
  const { brandId } = useParams();

  const [, setHeader] = useHeader();
  const [selectedBrand] = useSelectedBrand();
  const [posts, setPosts] = useState([]);

  const { isLoading } = useQuery(["getBrandPosts", brandId], getBrandPostsApi, {
    enabled: Boolean(brandId),
    onSuccess: (data) => {
      setPosts(data);
    },
  });

  const { mutate: deletePost } = useMutation(deletePostApi, {
    onSuccess: () => {
      // queryClient.invalidateQueries(["getBrandPosts"]);
    },
    onError: () => {
      notification.error({
        message:
          "There was a problem deleting your post, please try again later",
      });
      queryClient.invalidateQueries(["getBrandPosts"]);
    },
  });

  useEffect(() => {
    setHeader(`${selectedBrand?.name}' Posts`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateToEdit = (postId) => {
    setIsEditingPost({ showEditor: !isEditingPost?.showEditor, postId });
  };

  const handleCloseEdit = () => {
    setIsEditingPost({ showEditor: false, postId: null });
  };

  const handleDeletePost = (postId) => {
    setPosts(posts?.filter((post) => post?.id !== postId));
    deletePost({ brandId, postId });
  };

  const handleMenuClick = (e, postId) => {
    if (e?.key === "delete-post") {
      handleDeletePost(postId);
    }
  };

  return (
    <div>
      {isLoading ? (
        <BoxLoader />
      ) : (
        <div>
          {isEditingPost?.showEditor && (
            <EditAsset
              open={isEditingPost?.showEditor}
              brandId={brandId}
              postId={isEditingPost?.postId}
              onCancel={handleCloseEdit}
            />
          )}

          {posts?.map((post) => (
            <div
              key={post?.id}
              style={{
                marginBottom: "8px",
                padding: "8px",
                border: "1px solid #e3e3e3",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  margin: "8px",
                  marginLeft: "24px",
                  marginBottom: "0px",
                  display: "flex",
                  width: "100%",
                  justifyItems: "flex-start",
                }}
              >
                <Text strong style={{ fontSize: "17px" }}>
                  Idea: {post?.headline}
                </Text>
              </div>
              <div
                style={{
                  margin: "8px",
                  marginLeft: "24px",
                  display: "flex",
                  width: "100%",
                  justifyItems: "flex-start",
                }}
              >
                <Text>{post?.idea_category}</Text>
              </div>
              <div style={{ margin: "8px", marginTop: "0px", display: "flex" }}>
                <ChatbotIcon style={{ width: "20%" }} />
                <Text>{post?.one_line_benefit}</Text>
              </div>
              <img src={post?.image_url} alt="brand logo" width={"350px"}></img>
              <div>
                <Text strong>{post?.caption}</Text>
              </div>
              <Row
                style={{ marginTop: "8px", flexWrap: "nowrap" }}
                align={"middle"}
              >
                <Button
                  size="small"
                  type="primary"
                  ghost={true}
                  onClick={() => handleNavigateToEdit(post?.id)}
                >
                  {" "}
                  Edit{" "}
                </Button>
                <Dropdown.Button
                  size="small"
                  className="ml-8"
                  menu={{
                    items: dropDownItems,
                    onClick: (e) => handleMenuClick(e, post?.id),
                  }}
                  buttonsRender={([leftButton, rightButton]) => [
                    null,
                    rightButton,
                  ]}
                ></Dropdown.Button>
              </Row>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

PostsList.propTypes = {};

export default PostsList;
