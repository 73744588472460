import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useMutation } from "react-query";
import { Form, Input, Button, Row, Typography } from "antd";

import { InlineSpinner } from "common/components";
import { extractInfoApi } from "promote-your-work/api";

const { Text } = Typography;

const ExtractionForm = ({
  isCreating = false,
  extractedInfo = {},
  handleCreatePost = () => {},
}) => {
  const handleSubmit = (values) => {
    const formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    handleCreatePost({ formData });
  };

  return (
    <Form initialValues={extractedInfo} onFinish={handleSubmit}>
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: "Please input title",
          },
        ]}
        required={false}
      >
        <Input placeholder="Ex: Top 10 tips to run online coaching business" />
      </Form.Item>

      <Form.Item label="Image URL" name="image_url">
        <Input placeholder="Understand what is working and what is not in online business" />
      </Form.Item>

      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const currentImgUrl = getFieldValue("image_url");

          return (
            <img
              alt="logo"
              srcSet={currentImgUrl}
              width="150px"
              height={"150px"}
            />
          );
        }}
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input placeholder="Understand what is working and what is not in online business" />
      </Form.Item>

      <Row justify="center" style={{ marginTop: "24px" }}>
        <Button type="primary" ghost={true} htmlType="submit">
          {isCreating && (
            <InlineSpinner className="mr-8" fontSize={24} color="white" />
          )}
          Generate Post
        </Button>
      </Row>
    </Form>
  );
};

const BlogPost = ({ isCreating = false, handleCreatePost = () => {} }) => {
  const [extractedInfo, setExtractedInfo] = useState(null);
  const [blogUrl, setBlogUrl] = useState("");

  const { mutate: extractInfo } = useMutation(extractInfoApi, {
    onSuccess: (data) => {
      setExtractedInfo(data);
    },
  });

  const handleInforExtraction = () => {
    extractInfo({ blogUrl });
  };

  const handleChangeBlogUrl = (e) => {
    setBlogUrl(e?.target?.value);
  };

  return (
    <div>
      <div style={{ marginBottom: "16px" }}>
        <Text type="secondary">URL</Text>
        <Input value={blogUrl} onChange={handleChangeBlogUrl} />
      </div>

      {extractedInfo && (
        <ExtractionForm
          isCreating={isCreating}
          extractedInfo={extractedInfo}
          handleCreatePost={handleCreatePost}
        />
      )}

      {!extractedInfo && (
        <Row justify="center" style={{ marginTop: "24px" }}>
          <Button type="primary" ghost={true} onClick={handleInforExtraction}>
            Generate Post
          </Button>
        </Row>
      )}
    </div>
  );
};

BlogPost.propTypes = {};

export default BlogPost;
