import React from "react";
import { useNavigate } from "react-router-dom";
import { BottomNavigation } from "reactjs-bottom-navigation";

import { useSelectedBrand } from "store";
import {
  HomeOutlined,
  AppstoreAddOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const getSelectedTab = () => {
  const pathName = window.location.pathname;

  if (pathName?.includes("/create-new-post")) {
    return 2;
  }

  if (pathName?.includes("/brand/")) {
    return 1;
  }

  return 0;
};

const NavWrapper = ({ firstBrandId = 1, children }) => {
  const navigate = useNavigate();

  const [selectedBrand] = useSelectedBrand();

  const handleHomeClick = () => {
    navigate("/brands");
  };

  const handlePostsClick = () => {
    navigate(`/brand/${selectedBrand?.id}`);
  };

  const handleAddClick = () => {
    navigate(`/create-new-post/${selectedBrand?.id}`);
  };

  const bottomNavItems = [
    {
      title: "Home",
      onClick: handleHomeClick,
      icon: <HomeOutlined />, // just for example
      activeIcon: <HomeOutlined color="#fff" />,
    },
    {
      title: "Posts",
      onClick: handlePostsClick,
      icon: <AppstoreAddOutlined />,
      activeIcon: <AppstoreAddOutlined color="#fff" />,
    },
    {
      title: "Add",
      onClick: handleAddClick,
      icon: <PlusCircleOutlined />,
      activeIcon: <PlusCircleOutlined color="#fff" />,
    },
  ];

  const selectedTab = getSelectedTab();

  return (
    <div>
      {children}
      <BottomNavigation
        items={bottomNavItems}
        selected={selectedTab}
        activeBgColor="slateBlue"
        activeTextColor="white"
      />
    </div>
  );
};

NavWrapper.propTypes = {};

export default NavWrapper;
