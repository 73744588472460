import React, { useState } from "react";
// import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Upload,
  TimePicker,
} from "antd";

import { InlineSpinner } from "common/components";

const Event = ({ isCreating = false, handleCreatePost = () => {} }) => {
  const [imgFileList, setImgFileList] = useState([]);

  const onFinish = (values) => {
    const formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }
    formData.append("image", imgFileList[0]);

    handleCreatePost({ formData });
  };

  const onFinishFailed = () => {};

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Please input your title!",
            },
          ]}
          required={false}
        >
          <Input placeholder="Ex: Talk on Social Media Marketing" />
        </Form.Item>

        <Row>
          <Col xs={12}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please input your date!",
                },
              ]}
              required={false}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Time"
              name="time"
              rules={[
                {
                  required: true,
                  message: "Please input your time!",
                },
              ]}
              required={false}
            >
              <TimePicker format={"HH:mm"} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Venue"
          name="venue"
          rules={[
            {
              required: true,
              message: "Please input your venue!",
            },
          ]}
          required={false}
        >
          <Input placeholder="Ex: Zoom Call" />
        </Form.Item>

        <Form.Item label="Other Details (optional)" name="other_details">
          <Input placeholder="Other Details" />
        </Form.Item>

        <Form.Item
          label="Image"
          name="image"
          rules={[
            {
              required: true,
              message: "Please select an image",
            },
          ]}
          required={false}
        >
          <Upload
            beforeUpload={(file, fileList) => {
              setImgFileList(fileList);
            }}
            onRemove={() => {
              setImgFileList([]);
            }}
          >
            <Button>Upload</Button>
          </Upload>
        </Form.Item>

        <Row justify={"center"}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isCreating && (
                <InlineSpinner className="mr-8" fontSize={24} color="white" />
              )}
              Generate Post
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

Event.propTypes = {};

export default Event;
