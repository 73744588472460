import React from "react";
// import PropTypes from 'prop-types';
import { observer } from "mobx-react-lite";
import { Select } from "antd";

const MyTextFontSize = observer(({ store, element, elements }) => {
  return (
    <Select
      value={element.fontSize}
      onChange={(val) => {
        console.log(val);
        element.set({
          fontSize: val,
        });
      }}
      options={[
        {
          value: 32,
          label: "32",
        },
        {
          value: 42,
          label: "42",
        },
        {
          value: 64,
          label: "64",
        },
        {
          value: 80,
          label: "80",
        },
      ]}
    />
  );
});

MyTextFontSize.propTypes = {};

export default MyTextFontSize;
