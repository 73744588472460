import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { Form, Input, Button, Upload, Row } from "antd";

import { InlineSpinner } from "common/components";

const Announcment = ({ isCreating = false, handleCreatePost }) => {
  const [imgFileList, setImgFileList] = useState([]);

  const onFinish = (values) => {
    const formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }
    formData.append("image", imgFileList[0]);

    handleCreatePost({ formData });
  };

  return (
    <div>
      <Form onFinish={onFinish}>
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Please input title",
            },
          ]}
          required={false}
        >
          <Input placeholder="Ex: We are hiring" />
        </Form.Item>

        <Form.Item label="Details" name="details">
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Image"
          name="image"
          rules={[
            {
              required: true,
              message: "Please select an image",
            },
          ]}
          required={false}
        >
          <Upload
            beforeUpload={(file, fileList) => {
              setImgFileList(fileList);
            }}
            onRemove={() => {
              setImgFileList([]);
            }}
          >
            <Button>Upload</Button>
          </Upload>
        </Form.Item>

        <Row justify="center" style={{ marginTop: "24px" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isCreating && (
                <InlineSpinner className="mr-8" fontSize={24} color="white" />
              )}
              Generate Post
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

Announcment.propTypes = {};

export default Announcment;
