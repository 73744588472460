import React from "react";
import { observer } from "mobx-react-lite";
import { Select } from "antd";

const TextChangeText = observer(({ store, element, elements }) => {
  return (
    <Select
      style={{ marginLeft: "8px" }}
      placeholder="Change Text"
      onChange={(val) => {
        console.log(val);
        element.set({
          text: val,
        });
      }}
      options={[
        {
          value: "Funny",
          label: "Funny",
        },
        {
          value: "Serious",
          label: "Serious",
        },
        {
          value: "professional",
          label: "Professional",
        },
        {
          value: "Sarcastic",
          label: "Sarcastic",
        },
      ]}
    />
  );
});

TextChangeText.propTypes = {};

export default TextChangeText;
