import React, { useState, useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { Button, Modal, Input, Empty } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
// import PropTypes from 'prop-types';
import { BoxLoader } from "common/components";
import { createStore } from "polotno/model/store";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel, DEFAULT_SECTIONS } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import { Dropdown } from "antd";

import { getVideoUrlsApi } from "studio-editor/apis/studioEditorApis";
import { SectionTab } from "polotno/side-panel";
import MdVideoLibrary from "@meronex/icons/md/MdVideoLibrary";
import { unstable_setAnimationsEnabled } from "polotno/config";

unstable_setAnimationsEnabled(true);

const ActionControls = ({ store, setJsonContent }) => {
  const items = [
    {
      label: "Save as Image",
      key: "save-as-image",
    },
    {
      label: "Save as GIF",
      key: "save-as-gif",
    },
    {
      label: "Save as Video",
      key: "save-as-video",
    },
    {
      label: "Save JSON",
      key: "save-json",
    },
  ];

  const handleClick = (e) => {
    if (e?.key === "save-as-image") {
      store.saveAsImage();
    } else if (e?.key === "save-as-gif") {
      store.saveAsGIF();
    } else if (e?.key === "save-as-video") {
    } else {
      const storeJson = store.toJSON();
      setJsonContent(storeJson);
    }
  };

  return (
    <div>
      <Dropdown menu={{ items, onClick: handleClick }}>
        <Button
          size="small"
          type="primary"
          ghost={true}
          style={{ marginLeft: "8px" }}
        >
          <DownloadOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export const PhotosPanel = observer(({ store }) => {
  const {
    mutate: getVideoUrls,
    data: videos,
    isLoading,
  } = useMutation(getVideoUrlsApi);

  useEffect(() => {
    getVideoUrls({ description: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceTimeOut = 800;

  const handleSearch = useMemo(() => {
    const loadOptions = (e) => {
      const value = e.target.value;
      console.log(value);
      if (value?.length >= 3) {
        getVideoUrls({ description: value });
      }
    };

    return debounce(loadOptions, debounceTimeOut);
  }, [getVideoUrls, debounceTimeOut]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Input
        leftIcon="search"
        placeholder="Search..."
        onChange={handleSearch}
        style={{
          marginBottom: "20px",
        }}
      />
      {isLoading && <BoxLoader />}
      <div
        style={{
          height: "90vh",
          overflowY: "auto",
        }}
      >
        {videos?.length === 0 && (
          <Empty description="No videos to display please try a different search" />
        )}
        {videos?.map((videoUrl, idx) => (
          <video
            key={`vid-${idx + 1}`}
            width={250}
            height={250}
            src={videoUrl}
            onClick={() => {
              // const { width, height } = await getImageSize(image.url);
              store.activePage.addElement({
                type: "video",
                src: videoUrl,
                width: 250,
                height: 250,
                // if position is available, show image on dropped place
                // or just show it in the center
                x: store.width / 2 - 250 / 2,
                y: store.height / 2 - 250 / 2,
              });
            }}
          />
        ))}
      </div>
    </div>
  );
});

const CustomPhotos = {
  name: "videos",
  Tab: (props) => (
    <SectionTab name="Videos" {...props}>
      <MdVideoLibrary />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: PhotosPanel,
};

const store = createStore();

const StudioEditor = (props) => {
  const [jsonContent, setJsonContent] = useState();

  useEffect(() => {
    store.addPage();
  }, []);

  if (!store) {
    return null;
  }

  return (
    <div>
      {Boolean(jsonContent) && (
        <Modal
          open={Boolean(jsonContent)}
          onCancel={() => {
            setJsonContent(null);
          }}
          footer={
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(JSON.stringify(jsonContent));
                }}
              >
                Copy
              </Button>
              <Button
                style={{ marginLeft: "8px" }}
                onClick={() => {
                  setJsonContent(null);
                }}
              >
                Close
              </Button>
            </div>
          }
        >
          {JSON.stringify(jsonContent)}
        </Modal>
      )}
      <PolotnoContainer style={{ width: "100vw", height: "100vh" }}>
        <SidePanelWrap>
          <SidePanel
            store={store}
            sections={[...DEFAULT_SECTIONS, CustomPhotos]}
          />
        </SidePanelWrap>

        <WorkspaceWrap>
          <Toolbar
            store={store}
            downloadButtonEnabled
            components={{
              ActionControls: ({ store }) => (
                <ActionControls store={store} setJsonContent={setJsonContent} />
              ),
            }}
          />
          <Workspace store={store} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </div>
  );
};

StudioEditor.propTypes = {};

export default StudioEditor;
