import React, { useEffect, useRef } from "react";
// import PropTypes from 'prop-types';
import { Modal } from "antd";
import { createStore } from "polotno/model/store";
import { useQuery, useMutation } from "react-query";

import { BoxLoader } from "common/components";
import { Editor } from "common/components";
import { updatePostApi, getPostApi } from "posts/apis/postsApi";

import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
// import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

const CreateAssetFromIdea = ({
  open = false,
  brandId,
  postId,
  onClose,
  onSave,
}) => {
  const storeRef = useRef(createStore());

  const { mutate: updatePost, isLoading: isUpdating } = useMutation(
    updatePostApi,
    {
      onSuccess: () => {
        const params = new URLSearchParams(window.location.search);
        const redirectUrl = params.get("redirect_url");
        if (redirectUrl) {
          window.location = redirectUrl;
        } else {
          onSave();
        }
      },
    }
  );

  const handleDiscard = () => {
    const params = new URLSearchParams(window.location.search);

    const redirectUrl = params.get("redirect_url");
    if (redirectUrl) {
      window.location = redirectUrl;
    } else {
      onClose();
    }
  };

  const handleSave = () => {
    updatePost({
      brandId,
      postId: data?.id,
      post: {
        asset_attributes: {
          canvas_state: storeRef?.current?.toJSON(),
        },
      },
    });
  };

  const { isLoading, data } = useQuery(
    ["generateIdea", brandId, postId],
    getPostApi,
    {
      enabled: open,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      storeRef?.current?.loadJSON(data?.asset?.canvas_state);
      storeRef?.current?.setScale(0.25);
    }
  }, [data]);

  return (
    <div>
      <div id="create-asset-from-idea-container"></div>
      <Modal
        open={open}
        footer={false}
        closeIcon={false}
        width={"100%"}
        zIndex={2}
        getContainer={() =>
          document.getElementById("create-asset-from-idea-container")
        }
        destroyOnClose={true}
      >
        {isLoading ? (
          <BoxLoader />
        ) : (
          <Editor
            isAiImages={data?.ai_pics}
            brandId={brandId}
            postId={postId}
            isUpdating={isUpdating}
            store={storeRef?.current}
            invalidateQueries={["getPendingTasks"]}
            handleDiscard={handleDiscard}
            handleSave={handleSave}
          />
        )}
      </Modal>
    </div>
  );
};

CreateAssetFromIdea.propTypes = {};

export default CreateAssetFromIdea;
