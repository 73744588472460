import { atom, useRecoilState } from "recoil";

const selectedBrandState = atom({
  key: "selectedBrand",
  default: null,
});

export const useSelectedBrand = () => {
  const [selectedBrand, setSelectedBrand] = useRecoilState(selectedBrandState);

  return [selectedBrand, setSelectedBrand];
};

const headerState = atom({
  key: "headerState",
  default: null,
});

export const useHeader = () => {
  const [header, setHeader] = useRecoilState(headerState);

  return [header, setHeader];
};
