import React from "react";
// import PropTypes from 'prop-types';
import { notification } from "antd";
import { useParams, useNavigate } from "react-router";
import { BoxLoader } from "common/components";
import { useQuery } from "react-query";
import { generateIdeaApi } from "posts/apis/postsApi";

const RedirectToPost = (props) => {
  const navigate = useNavigate();

  const { brandId, ideaId } = useParams();

  const { isLoading } = useQuery(
    ["generateIdea", brandId, ideaId],
    generateIdeaApi,
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        navigate(`/create-new-post/${brandId}/idea/${ideaId}/post/${data?.id}`);
      },
      onError: () => {
        notification.error({
          message:
            "Something unexpected happened, please try again later if the posts was not created.",
          duration: 0,
        });
      },
    }
  );

  return (
    <div>
      {isLoading && (
        <div>
          Generating Post ....
          <BoxLoader />
        </div>
      )}
    </div>
  );
};

RedirectToPost.propTypes = {};

export default RedirectToPost;
