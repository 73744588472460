import React, { useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Modal } from "antd";

import { BoxLoader, Editor } from "common/components";
import { getPostApi, updatePostApi } from "posts/apis/postsApi";
import { createStore } from "polotno/model/store";

import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
// import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

const EditAsset = ({ open, brandId, postId, onCancel }) => {
  const queryClient = useQueryClient();
  const storeRef = useRef(createStore());

  const { isLoading, data } = useQuery(
    ["getPost", brandId, postId],
    getPostApi,
    {
      enabled: Boolean(brandId),
      cacheTime: 0,
    }
  );

  const { mutate: updatePost, isLoading: isUpdating } = useMutation(
    updatePostApi,
    {
      onSuccess: () => {
        const params = new URLSearchParams(window.location.search);
        const redirectUrl = params.get("redirect_url");
        queryClient.invalidateQueries(["getBrandPosts"]);
        if (redirectUrl) {
          window.location = redirectUrl;
        } else {
          onCancel();
        }
      },
    }
  );

  useEffect(() => {
    if (data?.asset?.canvas_state) {
      storeRef.current.loadJSON(data?.asset?.canvas_state);
      storeRef.current.setScale(0.25);
    }
  }, [data]);

  const handleDiscard = () => {
    const params = new URLSearchParams(window.location.search);

    const redirectUrl = params.get("redirect_url");
    if (redirectUrl) {
      window.location = redirectUrl;
    } else {
      onCancel();
    }
  };

  const handleSave = (variant, layout) => {
    updatePost({
      brandId,
      postId,
      post: {
        asset_attributes: {
          canvas_state: storeRef?.current?.toJSON(),
          variant,
          style: layout,
        },
      },
    });
  };

  return (
    <div>
      <div id="edit-asset-container" />

      <Modal
        open={open}
        width={"100%"}
        closeIcon={false}
        footer={null}
        zIndex={2}
        getContainer={() => document.getElementById("edit-asset-container")}
        destroyOnClose={true}
      >
        {isLoading ? (
          <BoxLoader />
        ) : (
          <div>
            <Editor
              isAiImages={data?.ai_pics}
              brandId={brandId}
              postId={postId}
              isUpdating={isUpdating}
              store={storeRef?.current}
              defaultLayout={data?.asset?.style}
              defaultVariant={data?.asset?.variant}
              invalidateQueries={["getBrandPosts"]}
              handleDiscard={handleDiscard}
              handleSave={handleSave}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

EditAsset.propTypes = {};

export default EditAsset;
