import React from "react";
// import PropTypes from 'prop-types';
import { createStore } from "polotno/model/store";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";

import LOAD_TEMPLATE_JSON from "load-from-saved/templates/template3.json";
const store = createStore();

store.loadJSON(LOAD_TEMPLATE_JSON);

const LoadFromSaved = (props) => {
  if (!store) {
    return null;
  }

  return (
    <div className="polotno-mobile">
      <PolotnoContainer style={{ width: "100vw", height: "100vh" }}>
        <SidePanelWrap>
          <SidePanel store={store} />
        </SidePanelWrap>

        <WorkspaceWrap>
          <Toolbar store={store} downloadButtonEnabled />
          <Workspace store={store} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </div>
  );
};

LoadFromSaved.propTypes = {};

export default LoadFromSaved;
