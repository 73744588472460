import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useMutation } from "react-query";
import { Modal, Button, Upload } from "antd";

import { BoxLoader } from "common/components";
import { useImageOptions } from "common/components/editor/hooks";
import { saveBrandImageApi, deleteBrandImageApi } from "posts/apis/postsApi";

const ImageChangeImageDialog = ({
  isOpen,
  isAiImages,
  brandId,
  postId,
  handleClick,
  onClose,
}) => {
  const [imgFileList, setImgFileList] = useState([]);

  const { mutate: saveBrandImage } = useMutation(saveBrandImageApi, {
    onSuccess: () => {
      refetch();
    },
  });

  const { mutate: deleteBrandImage } = useMutation(deleteBrandImageApi, {
    onSuccess: () => {
      refetch();
    },
  });

  const { isLoading, data, refetch } = useImageOptions({
    isAiImages,
    brandId,
    postId,
  });

  const handleUpload = () => {
    const formData = new FormData();

    formData.append("image", imgFileList[0]);

    saveBrandImage({
      brandId,
      formData,
    });
  };

  const handleDelete = (imageId) => {
    deleteBrandImage({
      brandId,
      imgId: imageId,
    });
  };

  return (
    <Modal
      open={isOpen}
      closable={true}
      onCancel={onClose}
      maskClosable={false}
      title="Choose an Image"
      footer={null}
    >
      {isLoading && <BoxLoader />}

      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {data?.map(({ id, image_url }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              style={{ margin: "16px" }}
              width={"200px"}
              height={"200px"}
              src={image_url}
              alt="alt"
              onClick={() => handleClick(image_url)}
            />
            {!isAiImages && (
              <Button
                danger={true}
                ghost={true}
                onClick={() => handleDelete(id)}
              >
                Delete
              </Button>
            )}
          </div>
        ))}
      </div>

      {!isAiImages && (
        <div>
          <Upload
            accept={"image/png, image/gif, image/jpeg"}
            fileList={imgFileList}
            beforeUpload={(file, fileList) => {
              setImgFileList(fileList);
            }}
            onRemove={() => {
              setImgFileList([]);
            }}
          >
            <Button>Select Image</Button>
          </Upload>
          <Button
            type="primary"
            size="small"
            style={{ marginTop: "16px" }}
            onClick={handleUpload}
          >
            Upload
          </Button>
        </div>
      )}
    </Modal>
  );
};

ImageChangeImageDialog.propTypes = {};

export default ImageChangeImageDialog;
