import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import { Typography, Table } from "antd";

import { BoxLoader } from "common/components";
import CreateAssetFromIdea from "posts/components/assets/CreateAssetFromIdea";
import { getPendingTasks } from "posts/apis/postsApi";

const { Title, Text } = Typography;

const getColumns = ({ brandId, categoryNameMap, handleGoToPost }) => [
  {
    title: "Id",
    dataIndex: "id",
    name: "id",
  },
  {
    title: "Status",
    dataIndex: "status",
    name: "status",
    render: (status, record) => {
      return `${status} ${
        status === "created" ? moment(record?.created_at).fromNow() : ""
      }`;
    },
  },
  {
    title: "Idea Category",
    dataIndex: "idea_category_id",
    name: "category_name",
    render: (id) => {
      return categoryNameMap[id] || "-";
    },
  },
  {
    title: "Post",
    dataIndex: "post_id",
    name: "post_url",
    render: (id) => {
      if (id) {
        return (
          <Text
            className="text-primary cursro-pointer"
            onClick={() => {
              handleGoToPost(id);
            }}
          >
            Go to Post
          </Text>
        );
      } else {
        return "-";
      }
    },
  },
];

const PendingTasks = ({ brandId, categoryNameMap = {} }) => {
  const queryClient = useQueryClient();

  const [isEditingPost, setIsEditingPost] = useState({
    show: false,
    postId: null,
  });

  const { isLoading: isLoadingTasks, data: tasks } = useQuery(
    ["getPendingTasks", brandId],
    getPendingTasks,
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(brandId),
    }
  );

  useEffect(() => {
    const id = setInterval(() => {
      queryClient.invalidateQueries({
        queryKey: ["getPendingTasks"],
      });
    }, 60 * 1000);
    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToPost = (postId) => {
    setIsEditingPost({ show: true, postId });
  };

  const handleClosePost = () => {
    setIsEditingPost({ show: false, postId: null });
  };

  const filteredTasks =
    tasks?.filter((task) => {
      const timeDiffFromNow = moment()?.diff(
        moment(task?.created_at),
        "minutes"
      );
      return timeDiffFromNow < 30;
    }) || [];

  return (
    <div style={{ margin: "16px" }}>
      <Title level={4}>Pending Tasks</Title>
      {isLoadingTasks ? (
        <BoxLoader />
      ) : (
        <div>
          {isEditingPost?.show && (
            <CreateAssetFromIdea
              open={isEditingPost?.show}
              brandId={brandId}
              postId={isEditingPost?.postId}
              onClose={handleClosePost}
              onSave={() => {}}
            />
          )}
          <Table
            locale={{ emptyText: "No Pending tasks in last 30 mins" }}
            columns={getColumns({ brandId, categoryNameMap, handleGoToPost })}
            dataSource={filteredTasks}
          />
        </div>
      )}
    </div>
  );
};

PendingTasks.propTypes = {};

export default PendingTasks;
