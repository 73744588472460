import { useQuery } from "react-query";

import { getImageOptionsApi, getBrandImagesApi } from "posts/apis/postsApi";

export const useImageOptions = ({ isAiImages, brandId, postId }) => {
  console.log({ isAiImages });

  const {
    isLoading: isLoadingPhotos,
    data: imageOptions,
    refetch: refetchPhotos,
  } = useQuery(["getImageOptions", brandId, postId], getImageOptionsApi, {
    enabled: isAiImages,
  });

  const {
    data: brandImages,
    isLoading,
    refetch: refetchBrandImages,
  } = useQuery(["getBrandImages", brandId], getBrandImagesApi, {
    enabled: !isAiImages,
  });

  return {
    isLoading: isLoading || isLoadingPhotos,
    data: isAiImages
      ? imageOptions?.map((imgUrl) => ({ url: imgUrl })) || []
      : brandImages || [],
    refetch: isAiImages ? refetchPhotos : refetchBrandImages,
  };
};
