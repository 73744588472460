import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Row, Typography } from "antd";

import { BoxLoader } from "common/components";
import { useSelectedBrand, useHeader } from "store";
import { getBrandsApi } from "brands/brandsApi";

const { Text } = Typography;

const Brands = (props) => {
  const navigate = useNavigate();

  const [, setHeader] = useHeader();
  const [, setSelectedBrand] = useSelectedBrand();

  const { isLoading, data } = useQuery("getBrands", getBrandsApi);

  useEffect(() => {
    setHeader("Brands");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateToBrand = (brand) => {
    setSelectedBrand(brand);
    navigate(`/brand/${brand?.id}`);
  };

  return (
    <div>
      {isLoading ? (
        <BoxLoader />
      ) : (
        <div>
          <Row
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: "100%" }}>
              {data?.map((brand) => (
                <div
                  key={brand?.id}
                  style={{
                    marginBottom: "8px",
                    padding: "8px",
                    border: "1px solid #e3e3e3",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "128px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleNavigateToBrand(brand)}
                >
                  <Text strong>{brand?.name}</Text>
                  <img
                    src={brand?.logo_url}
                    alt="brand logo"
                    height={"40px"}
                  ></img>
                </div>
              ))}
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

Brands.propTypes = {};

export default Brands;
