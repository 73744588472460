import React from "react";
// import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Row, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Text } = Typography;

const Header = ({ backUrl = "", title = "" }) => {
  const naviagte = useNavigate();

  const handleNavigateBack = () => {
    naviagte(backUrl);
  };

  return (
    <div>
      <Row
        align={"middle"}
        style={{
          height: "48px",
          paddingLeft: "8px",
          borderBottom: "1px solid #e3e3e3",
          backgroundColor: "#D9D9D9",
        }}
      >
        <ArrowLeftOutlined onClick={handleNavigateBack} />

        <Text style={{ marginLeft: "24px" }}>{title}</Text>
      </Row>
    </div>
  );
};

Header.propTypes = {};

export default Header;
