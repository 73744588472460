import axios from "axios";

export const getBrandPostsApi = async ({ queryKey }) => {
  const brandId = queryKey ? queryKey[1] || null : null;

  if (brandId) {
    const { data } = await axios.get(`/brands/${brandId}/posts`);

    return data;
  }
};

export const getIdeaCategoryListApi = async ({ queryKey }) => {
  const brandId = queryKey ? queryKey[1] || null : null;

  const { data } = await axios.get(`/brands/${brandId}/meta_idea_categories`);

  return data;
};

export const generateIdeaApi = async ({ queryKey }) => {
  const brandId = queryKey ? queryKey[1] || null : null;
  const ideaId = queryKey ? queryKey[2] || null : null;

  const { data } = await axios.post(`/brands/${brandId}/posts`, {
    idea_category_id: ideaId,
  });

  return data;
};

export const generateIdeaMutationApi = async ({ brandId, ideaId }) => {
  const { data } = await axios.post(`/brands/${brandId}/posts`, {
    idea_category_id: ideaId,
  });

  return data;
};

export const getPostApi = async ({ queryKey }) => {
  const brandId = queryKey ? queryKey[1] || null : null;
  const postId = queryKey ? queryKey[2] || null : null;

  if (brandId) {
    const { data } = await axios.get(`/brands/${brandId}/posts/${postId}`);

    return data;
  }
};

export const getImageOptionsApi = async ({ queryKey }) => {
  const brandId = queryKey ? queryKey[1] || null : null;
  const postId = queryKey ? queryKey[2] || null : null;

  const { data } = await axios.get(`/brands/${brandId}/posts/${postId}/photos`);

  return data;
};

export const createPostApi = async ({ brandId, post }) => {
  const { data } = await axios.post(`/brands/${brandId}/posts`, {
    post,
  });

  return data;
};

export const updatePostApi = async ({ brandId, postId, post }) => {
  const { data } = await axios.patch(`/brands/${brandId}/posts/${postId}`, {
    post,
  });

  return data;
};

export const getBrandImagesApi = async ({ queryKey }) => {
  const brandId = queryKey ? queryKey[1] || null : null;
  const { data } = await axios.get(`/brands/${brandId}/brand_images`);

  return data;
};

export const saveBrandImageApi = async ({ brandId, formData }) => {
  const { data } = await axios.post(
    `/brands/${brandId}/brand_images`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const deleteBrandImageApi = async ({ brandId, imgId }) => {
  const { data } = await axios.delete(
    `/brands/${brandId}/brand_images/${imgId}`
  );

  return data;
};

export const getPendingTasks = async ({ queryKey }) => {
  const brandId = queryKey ? queryKey[1] || null : null;

  const { data } = await axios.get(`/brands/${brandId}/tasks`, {
    headers: {
      Accept: "application/json",
    },
  });

  return data;
};

export const deletePostApi = async ({ brandId, postId }) => {
  const { data } = await axios.delete(`/brands/${brandId}/posts/${postId}`, {
    headers: {
      Accept: "application/json",
    },
  });

  return data;
};

export const changeLayoutApi = async ({
  brandId,
  postId,
  canvas_state,
  layout,
  variant,
}) => {
  const { data } = await axios.post(
    `/brands/${brandId}/posts/${postId}/preview`,
    {
      canvas_state,
      style: layout,
      variant,
    }
  );

  return data;
};

export const getThemLayoutsApi = async ({ queryKey }) => {
  const brandId = queryKey ? queryKey[1] || null : null;
  const postId = queryKey ? queryKey[2] || null : null;

  const { data } = await axios.get(
    `/brands/${brandId}/posts/${postId}/theme_layouts`
  );

  return data;
};
