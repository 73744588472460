import React, { useState } from "react";
import { useParams } from "react-router";
import EditAsset from "posts/components/assets/EditAsset";

const EditAssetFromURL = (props) => {
  const { brandId, postId } = useParams();
  const [open, setOpen] = useState(true);

  const onCancel = () => {
    setOpen(!open);
  };

  return (
    <div>
      <EditAsset
        open={open}
        brandId={brandId}
        postId={postId}
        onCancel={onCancel}
      />
    </div>
  );
};

EditAssetFromURL.propTypes = {};

export default EditAssetFromURL;
