import { useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Row, Typography } from "antd";

import { BoxLoader } from "common/components";
import { useSelectedBrand, useHeader } from "store";
import { getBrandsApi } from "brands/brandsApi";
import NavWrapper from "common/components/NavWrapper";
import "./App.css";

const { Text } = Typography;

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API;

function App() {
  const navigate = useNavigate();

  const [header] = useHeader();
  const [, setSelectedBrand] = useSelectedBrand();

  const { isLoading } = useQuery("getBrands", getBrandsApi, {
    onSuccess: (data) => {
      setSelectedBrand(data[0]);
    },
  });

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/brands");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <BoxLoader />;
  }

  return (
    <div className="App">
      <Row
        className="header"
        justify={"center"}
        style={{ borderBottom: "1px solid #e3e3e3", padding: "8px" }}
      >
        <Text strong>{header}</Text>
      </Row>
      <div className="main-content">
        <Outlet />
      </div>
      <NavWrapper />
    </div>
  );
}

export default App;
