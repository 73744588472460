import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { createStore } from "polotno/model/store";

import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Button, Row, notification, Select } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { BoxLoader, InlineSpinner } from "common/components";
import MyTextFontSize from "./components/MyTextFontSize";
import ImageChangeImageBtn from "./components/ImageChangeImageBtn";
import TextChangeText from "./components/TextChangeText";

import {
  deletePostApi,
  changeLayoutApi,
  getThemLayoutsApi,
} from "posts/apis/postsApi";

import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { Workspace } from "polotno/canvas/workspace";

import LOAD_TEMPLATE_JSON from "load-from-saved/templates/template1.json";

// import "@blueprintjs/icons/lib/css/blueprint-icons.css";
// import "@blueprintjs/core/lib/css/blueprint.css";
// import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";

const editorStore = createStore();
editorStore.loadJSON(LOAD_TEMPLATE_JSON);

const Editor = ({
  isAiImages = false,
  brandId = null,
  postId = null,
  isUpdating = false,
  defaultLayout = "instagram_post",
  defaultVariant = "1",
  store = null,
  invalidateQueries = [],
  handleDiscard = () => {},
  handleSave = () => {},
}) => {
  const queryClient = useQueryClient();
  const [selectedSM, setSelectedSM] = useState(
    defaultLayout || "instagram_post"
  );
  const [selectedVariant, setSelectedVariant] = useState(defaultVariant);

  const { data: themLayouts, isLoading: isLoadingLayouts } = useQuery(
    ["getThemLayouts", brandId, postId],
    getThemLayoutsApi
  );

  const { mutate: deletePost, isLoading: isDeleting } = useMutation(
    deletePostApi,
    {
      onSuccess: () => {
        handleDiscard();
        queryClient.invalidateQueries(invalidateQueries);
      },
      onError: () => {
        notification.error({
          message: "There was an issue deleting your post",
        });
      },
    }
  );

  const { mutate: changeLayout } = useMutation(changeLayoutApi, {
    onSuccess: (data) => {
      store.loadJSON(data?.canvas_state);
    },
  });

  const handleDeletePost = (postId) => {
    deletePost({ brandId, postId });
  };

  const handleSelectSM = (value) => {
    setSelectedSM(value);
    changeLayout({
      brandId: brandId,
      postId: postId,
      canvas_state: store?.toJSON(),
      layout: value,
    });
  };

  return (
    <div>
      <PolotnoContainer style={{ width: "100%", height: "80vh" }}>
        <SidePanelWrap>
          <Row
            display={"flex"}
            style={{
              marginTop: "8px",
              marginBottom: "8px",
              flexWrap: "nowrap",
              overflowX: "scroll",
              width: "300px",
            }}
          >
            {isLoadingLayouts ? (
              <BoxLoader />
            ) : (
              <div>
                {Object.entries(themLayouts)?.map(([key, layout]) => (
                  <img
                    key={key}
                    style={{
                      height: "100px",
                      width: "100px",
                      minWidth: "100px",
                      marginLeft: "8px",
                      backgroundColor: "grey",
                    }}
                    src={layout}
                    alt="layout"
                    onClick={() => {
                      setSelectedVariant(key);
                      changeLayout({
                        brandId,
                        postId,
                        variant: key,
                        layout: selectedSM,
                        canvas_state: store?.toJSON(),
                      });
                    }}
                  ></img>
                ))}
              </div>
            )}
          </Row>
          <Row display={"flex"} justify={"center"} style={{ margin: "8px" }}>
            <Button
              size="small"
              style={{ marginRight: "8px" }}
              onClick={handleDiscard}
            >
              Discard
            </Button>
            <Button
              size="small"
              style={{ marginRight: "8px" }}
              danger
              ghost={true}
              onClick={() => handleDeletePost(postId)}
            >
              Delete{" "}
              {isDeleting && <InlineSpinner fontSize={20} color="white" />}
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                handleSave(selectedVariant, selectedSM);
              }}
            >
              Save {isUpdating && <InlineSpinner fontSize={20} color="white" />}
            </Button>
          </Row>
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar
            store={store || editorStore}
            downloadButtonEnabled
            components={{
              TextChangeText,
              TextFontFamily: () => {},
              TextFontSize: MyTextFontSize,
              TextFontVariant: () => {},
              TextFilters: () => {},
              TextSpacing: () => {},
              History: () => {},
              Group: () => {},
              Position: () => {},
              Opacity: () => {},
              Lock: () => {},
              SvgFlip: () => {},
              SvgFilters: () => {},
              Duplicate: () => {},
              Remove: () => {},
              ActionControls: () => {
                return (
                  <div>
                    <Select
                      value={selectedSM}
                      options={[
                        { value: "instagram_post", label: "IG Post" },
                        { value: "instagram_story", label: "IG Story" },
                      ]}
                      onChange={handleSelectSM}
                    />
                    <Button
                      size="small"
                      type="primary"
                      ghost={true}
                      style={{ marginLeft: "8px" }}
                      onClick={() => {
                        store.saveAsImage({ fileName: "output.mp4" });
                        // store.saveAsGIF();
                      }}
                    >
                      <DownloadOutlined />
                    </Button>
                  </div>
                );
              },
              ImageFilters: () => {},
              ImageFitToBackground: () => {},
              ImageRemoveClip: () => {},
              ImageRemoveBackground: () => {},
              ImageChangeImageBtn: ({ store, element, elements }) => (
                <ImageChangeImageBtn
                  isAiImages={isAiImages}
                  brandId={brandId}
                  postId={postId}
                  store={store}
                  element={element}
                  elements={elements}
                />
              ),
            }}
          />
          <Workspace store={store || editorStore} />
          <ZoomButtons store={store || editorStore} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </div>
  );
};

Editor.propTypes = {};

export default Editor;
